<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import {dateUtil} from "@/helpers/date-util";
import {currencyHelper} from "@/helpers/currency-helper";
import axios from "axios";
import {paginationHelper} from "@/helpers/pagination-helper";
import {swalHelper} from "@/helpers/swal-helper";
import {errorCatcher} from "@/helpers/error-catcher";
import Swal from "sweetalert2";

export default {
  computed: {
    paginationHelper() {
      return paginationHelper
    },

    currencyHelper() {
      return currencyHelper
    },

    dateUtil() {
      return dateUtil
    }
  },

  components: {

    Layout,
    PageHeader
  },

  data() {
    return {

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,

        items: []
      },

      products: null,
      shops: null

    };
  },

  methods: {

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('marketplace-user-subscriptions.title'),
          active: true
        }
      ]
    },

    async loadSubscriptions() {
      let page = this.table.currentPage - 1;
      if (page > 0) {
        page = this.table.currentPage * this.table.perPage - this.table.perPage;
      }

      const { data } = await axios.get(`/stripe/subscription/list`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          "page": page,
          "size": this.table.perPage,
          "forUser": true
        }
      });

      this.table.items = data.subscriptions;
      this.table.totalRows = data.count;
      this.table.rows = data.count;

      await this.loadProducts()
      await this.loadShops()
      return this.table.items;
    },

    async loadProducts() {
      const ids = [];
      for (let subscription of this.table.items) {
        ids.push(subscription.marketplaceOrderId);
      }

      const result = await axios.get(`/marketplace/order/products`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          "orderIds": ids.join(","),
        }
      });

      this.products = new Map(result.data.map((obj) => [obj.marketplaceOrderId, obj]));
    },

    async loadShops() {
      try {
        if (this.table.items.length === 0) {
          return;
        }

        const ids = [];
        for (let subscription of this.table.items) {
          if (subscription.shopId) {
            ids.push(subscription.shopId);
          }
        }

        const json = JSON.stringify({
          "ids": ids
        });

        const result = await axios.post(`/shop/fetch`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.shops = new Map(result.data.map((obj) => [obj.id, obj]));
      } catch (error) {
        console.log(error)
      }
    },

    getProduct(marketplaceOrderId) {
      if (!this.products) {
        return null
      }

      return this.products.get(marketplaceOrderId) || null
    },

    getShop(shopId) {
      if (!this.shops) {
        return null
      }

      return this.shops.get(shopId) || null
    },

    deactiveSubscription(subscription) {
      swalHelper.yesOrNo(() => {
        axios.post(`/stripe/subscription/${subscription.id}/cancel-period-end`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        }).then(() => {
          Swal.fire("Sukces!", "Subskrybcja została wyłączona pomyślnie!", "success").then(() => {
            setTimeout(() => this.$router.go(this.$router.currentRoute), 700)
          });
        }).catch((error) => {
          errorCatcher.catchErrors(error)
          this.submitted = false;
        })
      })
    },

    getFields() {
      return [
        {key: "shop", slot: true, label: 'Sklep'},
        {key: "image", slot: true, label: 'Miniaturka'},
        {key: "productName", slot: true, label: 'Nazwa produktu'},
        {key: "amount", slot: true, label: 'Ilość'},
        {key: "price", slot: true, label: 'Kwota całkowita'},
        {key: "internalStatus", slot: true, label: 'Status'},
        {
          key: 'currentPeriodEnd', label: 'Data wygasnięcia', formatter: value => {
            if (!value) {
              return "-"
            }

            return dateUtil.asDateTime(value)
          }
        },
        {key: "action", slot: true, label: 'Akcje'}
      ]
    },
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('marketplace-user-subscriptions.title')" :items="getItems()"/>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <ecat-table
                  :table="table"
                  :fields="getFields()"
                  :items="loadSubscriptions"
              >
                <template v-slot:shop="{ item }">
                  <span :set="shop = getShop(item.shopId)">{{
                      shop ? (shop.domain ? shop.domain : shop.name) : "-"
                    }}</span>
                </template>

                <template v-slot:action="{ item }">
                  <b-button variant="danger" v-if="item.internalStatus === 'ACTIVE'"
                            @click="deactiveSubscription(item)">
                    Dezaktywuj
                  </b-button>
                </template>

                <template v-slot:image="{ item }">
                  <img :src="getProduct(item.marketplaceOrderId).imageUrl" alt="Product Image"
                       class="rounded avatar-lg">
                </template>

                <template v-slot:productName="{ item }">
                    <span :set="product = getProduct(item.marketplaceOrderId)">
                      {{ product ? product.title : "" }}
                    </span>
                </template>

                <template v-slot:price="{ item }">
                    <span :set="product = getProduct(item.marketplaceOrderId)">
                      {{ product ? product.price : "" }}{{ currencyHelper.getCurrencyInfo(product.currency).symbol }}
                    </span>
                </template>

                <template v-slot:amount="{ item }">
                    <span :set="product = getProduct(item.marketplaceOrderId)">
                      {{ product ? product.quantity : "" }}
                    </span>
                </template>

                <template v-slot:internalStatus="{ item }">
              <span class="badge badge-soft-info">{{
                  $t('marketplace-user-subscriptions.status.' + item.internalStatus)
                }}</span>
                </template>
              </ecat-table>
            </div>
          </div>
        </div>
      </div>

  </Layout>
</template>