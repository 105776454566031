export const currencyHelper = {
    getCurrencyInfo
}

const currencies = new Map([
    ["PLN", {
        name: "Polish Złoty",
        symbol: "zł"
    }],
    ["USD", {
        name: "American Dollar",
        symbol: "$"
    }]

]);

function getCurrencyInfo(currency) {
    return currencies.get(currency)
}